import { useQuery } from "@tanstack/react-query"

import { GET_CART_LIST_ITEMS } from "../federated/queries"
import {
  type GetCartListItemsQuery,
  type GetCartListItemsQueryVariables,
} from "@/federatedGql/graphql"
import { federatedGqlClient } from "@/utils/graphqlClient"

export type TCartListItem = Extract<
  GetCartListItemsQuery["cartListV2"],
  { __typename: "Cart" }
>

type TUseGetCartItemsProps = {
  regionCode: GetCartListItemsQueryVariables
  isEnabled?: boolean
}

const useGetCartItems = ({
  regionCode,
  isEnabled = true,
}: TUseGetCartItemsProps) => {
  return useQuery<TCartListItem>({
    queryKey: ["cartListItem", { regionCode }],
    queryFn: async () => {
      const result = await federatedGqlClient.request(GET_CART_LIST_ITEMS, {
        regionCode: regionCode as string,
      })
      if (result.cartListV2.__typename == "GenericError") {
        throw result.cartListV2
      }
      return result.cartListV2
    },
    retry: 1,
    enabled: isEnabled,
  })
}

export default useGetCartItems
